* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased !important;
}

.container {
  padding-top: 130px;
}

.nav-container {
  margin-left: 15px;
}
.nav-container {
  margin-left: 15px;
}



.table-list {
  margin-top: 8px;
}


